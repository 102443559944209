import { Card, CardContent, Dialog } from '@material-ui/core';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { useGetCachedCourse, useUserRoleInCourse } from 'hooks/course';
import { getSignedFileUrl } from 'hooks/file';
import { useAuthUserFromRedux } from 'hooks/user';
import React from 'react';
import { useParams } from 'react-router';
import { trackEvent } from 'utils/userEvents';

type FileModalProps = {
  file: FileItem;
  onClose: () => void;
};

const FileModal = ({ file, onClose }: FileModalProps) => {
  const { data: signedUrls } = getSignedFileUrl(file.url);
  const { courseId } = useParams();
  const course = useGetCachedCourse(courseId);

  const authUser = useAuthUserFromRedux();
  const { isInstructorInCourse } = useUserRoleInCourse();

  const supportedExtensionsForPreview = ['pdf', 'docx', 'pptx', 'xlsx', 'jpg', 'jpeg', 'png'];
  const fileExtension = file.name.split('.').pop().toLowerCase();
  const isPreviewSupported = supportedExtensionsForPreview.includes(fileExtension) && Boolean(signedUrls);
  const isImage = ['jpg', 'jpeg', 'png'].includes(fileExtension);
  const isPDF = fileExtension === 'pdf';
  const isAnyOfficeDocument = ['docx', 'pptx', 'xlsx'].includes(fileExtension);
  const fullscreenPreview = isPDF || isAnyOfficeDocument;

  React.useEffect(() => {
    if (!signedUrls) {
      return;
    }
    trackEvent('File Preview Modal Opened', authUser, {
      fileExtension,
    });
  }, []);

  if (!signedUrls) {
    return null;
  }

  const allowDownloadFiles =
    !isPreviewSupported || course.institutionSettings.allowDownloadFiles || isInstructorInCourse;

  return (
    <Dialog open className="file-modal" fullScreen onClose={onClose}>
      <div className="file-modal__header">
        <span data-testid="file-title" className="file-modal__header-title">
          {file.name}
        </span>
        <div className="file-modal__header-actions">
          {allowDownloadFiles && (
            <a href={signedUrls.downloadUrl} target="_blank">
              <Button
                type="primary"
                data-testid="file-download"
                className="file-modal__header-actions--download"
                onClick={onClose}
              >
                <i className="fa fa-download" />
                <TranslatedText i18nKey="File.Download" />
              </Button>
            </a>
          )}
          <Button onClick={onClose} type="secondary" autoFocus>
            <TranslatedText i18nKey="Close" />
          </Button>
        </div>
      </div>
      <div className="file-modal__content">
        <Card className={`file-modal__content-card ${fullscreenPreview && 'file-modal__content-full-screen'}`}>
          {!isPreviewSupported && (
            <CardContent>
              <TranslatedText i18nKey="File.Preview.NotSupported" />
              {allowDownloadFiles && (
                <a href={signedUrls.downloadUrl} target="_blank">
                  <Button
                    type="primary"
                    data-testid="file-download"
                    className="file-modal__header-actions--download"
                    onClick={onClose}
                  >
                    <i className="fa fa-download" />
                    <TranslatedText i18nKey="File.Download" />
                  </Button>
                </a>
              )}
            </CardContent>
          )}
          {isPreviewSupported && isImage && (
            <CardContent>
              <img src={signedUrls.inlineUrl} aria-label={file.name} />
            </CardContent>
          )}
          {isPreviewSupported && isPDF && (
            <object data={signedUrls.inlineUrl} type="application/pdf" aria-label={file.name} />
          )}
          {isPreviewSupported && isAnyOfficeDocument && (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(signedUrls.inlineUrl)}`}
              aria-label={file.name}
            />
          )}
        </Card>
      </div>
    </Dialog>
  );
};

export default FileModal;
